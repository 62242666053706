import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getTicketTypes() {
    return this.http.get<any>(myGlobals.Settings_URL + '/taskstatus' + '/task_types');
  }

  getHoldStatus(tasktypeid: string) {
    return this.http.get<any>(`${myGlobals.Report}/tasks/holdstatus/${tasktypeid}`);
  }


  getTicketSummary(tasktypeid: string) {
    return this.http.get<any>(`${myGlobals.Report}/tasks/summary?tasktype_id=${tasktypeid}`);
  }

  getTicketTrends(tasktypeid: string) {
    let data = (tasktypeid == 'mytickets') ? 'mytickets' : '';
    tasktypeid = (tasktypeid == 'mytickets') ? 'all' : tasktypeid;
    return this.http.get<any>(`${myGlobals.Report}/tasks/trends/${tasktypeid}?data=${data}`);
  }

  getPracticeSummary() {
    return this.http.get<any>(myGlobals.Report + '/tasks/practicesummary');
  }
  getProviderSummary() {
    return this.http.get<any>(myGlobals.Report + '/tasks/providersummary');
  }

  getValidationsSummary(batch_id, timerange = {}, source= '') {
    let sourcePath = '';
    if(Object.keys(timerange).length !==0 && timerange.constructor === Object) { sourcePath += '&start_date=' + timerange['start_date'] + '&end_date=' + timerange['end_date']}
    if (source != '') { sourcePath += '&source=' + source; };
    return this.http.get<any>(myGlobals.Reports + '/validations?batch_id=' + batch_id + sourcePath);
  }

  getMyTickets() {
    return this.http.get<any>(`${myGlobals.Report}/tasks/summary/currentuser?tasktype_id=`);
  }

  getInsurancesummary() {
    return this.http.get<any>(myGlobals.Report + '/tasks/insurancesummary/currentuser');
  }
  
  getValidationsData(time_period, source= '') {
    let sourcePath = '';
    if (source != '') { sourcePath += '&source=' + source; }
    let start_date = time_period.start_date;
    let end_date = time_period.end_date;
    return this.http.get<any>(myGlobals.Reports + '/validations/permonth?start_date=' + start_date + '&end_date=' + end_date + sourcePath);
  }

  draftChargesDownload(practices) {
    const url = myGlobals.charges_URL + '/draft/download';
    
      return this.http.post(url, {practices:practices}, { responseType: 'blob' });
    
  }
}
